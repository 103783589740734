<template>
	<div class="full-height">

		<div class="full-height flex-column">
			<div class="pa-10 box text-right">

				<date_picker
					:date="search.sDate"
					@click="setDate"
					class="mr-10"
				></date_picker>

				<select
					v-model="search.virtual_uid"
					class="mr-10 input-box-inline size-px-12"
					@change="getSearch(1)"
				>
					<option value="">지갑 구분</option>
					<option
						v-for="(virtual, v_index) in items_virtual"
						:key="'virtual_' + v_index"
						:value="virtual.uid"
					>{{ virtual.virtual_name }}</option>
				</select>

				<select
					v-model="search.is_settlement"
					class="pa-5 box mr-10"
					@change="getSearch(1)"
				>
					<option value="">정산여부</option>
					<template
						v-for="code in codes.S001.items"
					>
						<option
							v-if="code.sub_code > 0"
							:key="'admin_type_' + code.total_code"
							:value="code.code_value"
						>{{ code.code_name }}</option>
					</template>
				</select>

				<select
					v-model="search.is_deposit"
					class="pa-5 box mr-10"
					@change="getSearch(1)"
				>
					<option value="">지급여부</option>
					<template
						v-for="code in codes.S002.items"
					>
						<option
							v-if="code.sub_code > 0"
							:key="'admin_type_' + code.total_code"
							:value="code.code_value"
						>{{ code.code_name }}</option>
					</template>
				</select>

				<select
					v-model="search.search_type"
					class="pa-5 box mr-10"
				>
					<option value="">검색 조건</option>

					<option
						value="member_info.member_id"
					>아이디</option>
					<option
						value="member_info.member_name"
					>이름</option>

				</select>

				<input
					v-model="search.search_value"
					class="pa-5-10 box vertical-middle mr-10 "
					placeholder="검색어를 입력하세요"
					@keyup.enter="getSearch"
				/>

				<button
					class="pa-5-10 btn-blue mr-10 vertical-middle"
					@click="getSearch"
				>검색</button>
			</div>

			<div class="full-height ">
				<div class="mt-10 pa-10 bg-white full-height flex-column overflow-y-auto">
					<div
						v-if="items.length > 0"
					>
						<div
							v-if="user.account_type == 'A001003'"
							class="justify-end gap-10 items-center font-weight-bold"
						>
							보유 잔액: {{ item_wallet.balance | makeComma}}원
							<button
								@click="postRefresh"
							><v-icon small class="color-primary" name="refresh">mdi mdi-refresh</v-icon></button>
						</div>
						<table
							class="table table-even mt-10"
						>
							<colgroup>
								<col width="60px" />
								<col width="60px" />

							</colgroup>
							<thead>
							<tr>
								<th>
									<button
										@click="setAll"
									>
										<v-icon
											v-if="is_all"
											class="color-primary">mdi-checkbox-marked</v-icon>
										<v-icon
											v-else
										>mdi-checkbox-blank-outline</v-icon>
									</button>
								</th>
								<th>No</th>
								<th>입금일</th>
								<th
									v-if="auth.is_admin"
								>총판</th>
								<th
									v-if="!auth.is_agency"
								>대리점</th>
								<th>회원 아이디</th>
								<th>회원 이름</th>

								<th>입금 지급 금액</th>
								<th>정산여부</th>
								<th>지급여부</th>
								<th>관리</th>
							</tr>
							</thead>
							<tbody>
							<tr
								v-for="(item, index) in item_list"
								:key="'settlement_' + index + '_' + item.uid"
							>
								<td>
									<button
										v-if="item.uid && item.is_deposit != 1"
										@click="setAllItem(item)"
									>
										<v-icon
											v-if="item.is_all"
											class="color-primary">mdi-checkbox-marked</v-icon>
										<v-icon
											v-else
										>mdi-checkbox-blank-outline</v-icon>
									</button>
								</td>
								<td>{{ items.length - index}}</td>
								<td>{{ item.sDate ? item.sDate : "-" }}</td>
								<td
									v-if="auth.is_admin"
								>{{ item.upper_name ? item.upper_name : '-' }}</td>
								<td
									v-if="!auth.is_agency"
								>{{ item.agency_name ? item.agency_name : '-' }}</td>
								<td>{{ item.member_id}}</td>
								<td>{{ item.member_name }}</td>

								<td><input v-if="auth.is_agency && item.is_deposit != 1" v-model="item.amount" class="input-box" type="number" :rules="$rules.max(item, 'amount', 9)" /> <span v-else-if="item.amount">{{ item.amount | makeComma}} 원</span><span v-else>-</span></td>

								<td>{{ item.is_settlement_name }}</td>
								<td>{{ item.is_deposit_name }}</td>
								<td>
									<button
										v-if="item.uid"
										class="btn-blue pa-5-10 mr-10"
										@click="toDetail(item)"
									>상세 정보</button>
									<button
										v-if="item_detail.is_settlement == '0'"
										v-show="false"
										class="btn-success pa-5-10"
										@click="doSettlementConfirm(item)"
									>정산 확인 <v-icon small class="color-eee">mdi mdi-chevron-right</v-icon></button>
									<button
										v-if="item_detail.is_settlement == '1' && item_detail.is_deposit == '0'"
										v-show="false"
										class="btn-success pa-5-10"
										@click="doDepositConfirm(item)"
									>지급 완료 <v-icon small class="color-eee">mdi mdi-chevron-right</v-icon></button>
								</td>
							</tr>
							</tbody>
						</table>
						<div
							v-if="user.account_type == 'A001003'"
							class="mt-10 text-right font-weight-bold"
						>지급 금액: {{ total_amount | makeComma}} 원 / 보유 잔액: {{ item_wallet.balance | makeComma}}원</div>
					</div>
					<Empty
						v-else
					></Empty>
					<div
						v-if="auth.is_agency"
						class="mt-auto pa-10 text-right"
					>

						<input :value="search.sDate" class="input-box-inline text-center bg-gray-light mr-10 " readonly/>

						<select
							v-model="search.virtual_uid"
							class="mr-10 input-box-inline size-px-12"
							disabled
						>
							<option value="">지갑 구분</option>
							<option
								v-for="(virtual, v_index) in items_virtual"
								:key="'virtual_' + v_index"
								:value="virtual.uid"
							>{{ virtual.virtual_name }}</option>
						</select>

						<button
							class="btn-inline btn-primary mr-10"
							@click="onSettlement"
						>선택 정산 처리</button>

						<button
							class="btn-inline btn-primary"
							@click="onDeposit"
						>선택 정산 지급</button>
					</div>
				</div>
			</div>
		</div>

		<PopupLayer
			v-if="is_on_detail"

			@cancel="is_on_detail = false"
		>
			<template
				v-slot:body
			>
				<div class="bg-white width-480 ma-auto flex-column justify-space-between " style="max-height: 480px">
					<div class="pa-10-20 justify-space-between under-line">
						<h4>지급 상세 내역</h4>
						<v-icon
							@click="is_on_detail = false"
						>mdi mdi-close</v-icon>
					</div>

					<div
						class="pa-20 overflow-y-auto"

					>
						<div>
							<table class="table table-even">

								<colgroup>
									<col width="120px" />
									<col width="auto" />
								</colgroup>
								<tbody>
								<tr>
									<th>처리일자</th>
									<td>{{ item_detail.wDate }}</td>
								</tr>
								<tr>
									<th>보낸계정</th>
									<td>{{ item_detail.agency_name }}</td>
								</tr>
								<tr>
									<th>받은계정</th>
									<td>{{ item_detail.member_name }}</td>
								</tr>
								<tr>
									<th>금액</th>
									<td :class="'color-' + item_detail.history_type_color">{{ item_detail.amount | makeComma }}원</td>
								</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</template>

		</PopupLayer>

		<Excel
			v-if="is_excel"
			:excel_data="excel_data"
		></Excel>
	</div>
</template>

<script>

import Empty from "@/view/Layout/Empty";
import date_picker from "@/components/DatePicker"
import Excel from "../../components/Excel";
import PopupLayer from "@/view/Layout/PopupLayer";

export default {
	name: 'WalletMember'
	, components: {PopupLayer, Empty, date_picker, Excel}
	, props: ['Axios', 'TOKEN', 'codes', 'user', 'auth']
	,data: function(){
		return {
			program: {
				name: '회원 지급 실행'
				,top: true
				,title: true
				,bottom: false
			}
			,is_excel: false
			,excel_data: {
				name: '회원 지급 내역'
				,header: [
					{ key: 0, name: '입금일', column: 'sDate'}
					,{ key: 0, name: '총판', column: 'upper_name'}
					,{ key: 0, name: '대리점', column: 'agency_name'}
					,{ key: 0, name: '아이디', column: 'member_id'}
					,{ key: 0, name: '이름', column: 'member_name'}
					,{ key: 0, name: '정산금액', column: 'amount'}
					,{ key: 0, name: '정산여부', column: 'is_settlement_name'}
					,{ key: 0, name: '지급여부', column: 'is_deposit_name'}
				]
				,content: null
			}
			,items: []
			,year_start: 2021
			,search: this.$storage.init({
				sDate: this.$date.getToday('-')
				, admin_type: ''
				, is_settlement: ''
				, is_deposit: ''
				, payment_type: ''
				, search_type: ''
				, search_value: ''
				, page: 1
				, virtual_uid: ''
				, shop_summary: ''
			})
			,item_detail: {

			}
			,item_settlement: null
			,item_deposit: null
			,is_modal: false
			,modal_option: {
				title: '정산 상세 내역'
				,top: true
				,bottom: false
			}
			,reason: ''
			, items_virtual: []
			, is_all: true
			, item_wallet: {}
			, is_on_detail: false
		}
	}
	,computed: {
		item_list: function(){

			return this.items.filter((item) => {

				item.is_settlement_name = '-'
				for(let i = 0; i < this.codes.S001.items.length; i ++){
					if(item.is_settlement == this.codes.S001.items[i].code_value){
						item.is_settlement_name = this.codes.S001.items[i].code_name
					}
				}
				item.is_deposit_name = '-'
				for(let i = 0; i < this.codes.S002.items.length; i ++){
					if(item.is_deposit == this.codes.S002.items[i].code_value){
						item.is_deposit_name = this.codes.S002.items[i].code_value == '0' ? '-' : this.codes.S002.items[i].code_name
					}
				}

				switch(item.account_type_code){
					case this.codes.type_code_admin:
						item.admin_type_name = '운영관리'
						item.is_admin = true
						break;
					case this.codes.type_code_distributor:
						item.admin_type_name = '총판'
						item.is_distributor = true
						break;
					case this.codes.type_code_agency:
						item.admin_type_name = '대리점'
						item.is_agency = true
						break;

					case this.codes.type_code_supply:
						item.admin_type_name = '공급사'
						item.is_supply = true
						break;
					default:
						break;
				}

				return item
			})
		}
		,year_list: function(){
			let start = 2021
			let date = new Date()
			let year = date.getFullYear()
			let years = []
			for(let i = start; i <= year; i++){
				years.push(i)
			}

			return years
		}
		, date: function(){
			let t = ''
			t = this.search.sDate
			return t
		}

		, depost_virtual_name: function(){
			let t = ''
			this.items_virtual.filter( (item) => {
				if(this.search.virtual_uid == item.uid ){
					t = item.virtual_name
				}
			})
			return t
		}
		, items_list: function(){
			let t = []
			this.items.filter( (item) => {
				if(item.is_all){
					t.push(item)
				}
			})

			return t.length > 0 ? JSON.stringify(t) : t
		}
		, total_amount: function(){
			let t = 0
			this.items.filter(( item) => {
				t += Number(item.amount)
			})
			return t
		}
	}
	,methods: {
		getData: async function(){
			try{
				this.$bus.$emit('on', true)

				const result = await this.$request.init({
					method: 'get'
					,url: 'management/getWalletSettlementMemberActList'
					,data: this.search
				})

				if(result.success){
					this.items = result.data.result
					this.items.filter( (item) => {
						this.$set(item, 'is_all', true)
					})
					this.item_wallet = result.data.wallet_info
					this.$storage.setQuery(this.search)
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message})
				}
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, postDeposit: async function(){

			try{
				this.$bus.$emit('on', true)

				const result = await this.$request.init({
					method: 'post'
					,url: 'management/postWalletDepositMember'
					,data: {
						items: this.items_list
					}
				})

				if(result.success){
					await this.getData()
					this.$bus.$emit('notify', { type: 'success', message: result.message})
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message})
				}
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		,success: function(){
			this.close()
			this.getData()
		}
		,close: function(){
			this.item_detail = {}
			this.item_settlement = null
			this.item_deposit = null
			this.is_modal = false
		}
		,toDetail: function(item){
			this.is_on_detail = true
			this.item_detail = item
		}
		,doSettlementConfirm: function(item){
			this.item_settlement = item
		}
		,doDepositConfirm: function(item){
			this.item_deposit = item
		}
		,setNotify: function({ type, message }){
			this.$bus.$emit('notify', { type: type, message: message })
		}
		,getSearch: function(page){
			if(page){
				this.search.page = page
			}

			this.getData()
		}
		, setDate: function(date){
			this.search.sDate = date
			this.getData()
		}
		,getExcel: async function(){
			try{
				this.$bus.$emit('on', true)

				const result = await this.$request.init({
					method: 'get'
					,url: 'management/getSettlementList'
					,data: {
						ATOKEN: this.search.ATOKEN
						, year: this.search.year
						, month:  this.search.month
						, day: this.search.day
						, admin_type: this.search.admin_type
						, is_settlement: this.search.is_settlement
						, is_deposit: this.search.is_deposit
						, payment_type: this.search.payment_type
						, search_type: this.search.search_type
						, search_value: this.search.search_value
						, page: 1
						, list_cnt: 2000
					}
				})

				if(result.success){
					this.excel_data.content = result.data
					this.is_excel = true
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message})
				}
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getVirtualList: async function() {
			try {
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'get'
					, url: 'management/getVirtualList'
					, data: {
					}
				})
				if (result.success) {
					this.items_virtual = result.data
					this.search.virtual_uid = this.items_virtual[0].uid
				} else {
					this.$bus.$emit('notify', {type: 'error', message: result.message})
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.$bus.$emit('on', false)
			}
		}
		, onSettlement: function(){
			if(this.search.virtual_uid){
				let t = this.date + '일 [' + this.depost_virtual_name +'] 선택된 항목을 정산 처리하시겠습니까?'
				if(this.search.shop_summary){
					t = this.date + '일 [' + this.depost_virtual_name +'] [' + this.search.shop_summary + '] 선택된 항목을 정산 처리하시겠습니까?'
				}
				if(confirm(t)){
					this.postSettlement();
				}
			}else{
				alert('가상계좌를 선택하세요')
			}
		}
		, onDeposit: function(){
			if(this.search.virtual_uid){
				let t = this.date + '일 [' + this.depost_virtual_name +'] 선택된 항목의 정산 금액을 지급하시겠습니까?'
				if(this.search.shop_summary){
					t = this.date + '일 [' + this.depost_virtual_name +'] [' + this.search.shop_summary + '] 선택된 항목의 정산 금액을 지급하시겠습니까?'
				}
				if(confirm(t)){
					this.postDeposit();
				}
			}else{
				alert('가상계좌를 선택하세요')
			}
		}
		, getSettlement: async function(){
			try {
				if(!confirm('정산 재조회 시 기지급 내역을 제외하고 정산내역을 삭제 후 재등록합니다. 계속 하시겠습니가?')){
					return false
				}
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: 'schedule/depositToUpper'
					, data: {
						sDate: this.search.sDate
					}
				})
				if (result.success) {
					await this.getData()
				} else {
					this.$bus.$emit('notify', {type: 'error', message: result.message})
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.$bus.$emit('on', false)
			}
		}
		, setAll: function(){
			this.is_all = !this.is_all
			this.items.filter( (item) => {
				item.is_all = this.is_all
			})
		}
		, setAllItem: function(item){
			item.is_all = !item.is_all
			if(!item.is_all){
				this.is_all = false
			}
			let t = false
			this.items.filter((item) => {
				if(!item.is_all){
					t = true
				}
			})
			this.is_all = !t
		}
		, postSettlement: async function(){
			try {
				if(this.items_list.length > 0){

					this.$bus.$emit('on', true)
					const result = await this.$request.init({
						method: 'post'
						, url: 'management/postWalletSettlementMemeber'
						, data: {
							items: this.items_list
							, sDate: this.search.sDate
						}
					})
					if (result.success) {
						await this.getData()
					} else {
						throw result.message
					}
				}else{
					throw "선택된 항목이 없습니다."
				}
			} catch (e) {
				console.log(e)
				this.$bus.$emit('notify', {type: 'error', message: e})
			} finally {
				this.$bus.$emit('on', false)
			}
		}
		, postRefresh: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					,url: 'management/postAgencyWalletRefresh'
				})

				if(result.success){
					this.item_wallet = result.data
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		,toExcel: function(){
			this.excel_data.content = this.items
			this.is_excel = true
		}
	}
	, async created() {
		this.$emit('onLoad', this.program)
		await this.getData()
		await this.getVirtualList()
	}

}
</script>
